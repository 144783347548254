import React from "react";

import Layout from "../../components/layout";
import SEO from "../../components/seo";
import Spinner from "../../components/spinner";

class Admin extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      configTwitchChannelId: undefined,
      configForceBits: false,
      configPassword: undefined,
      creditTwitchChannelId: undefined,
      creditTwitchUserId: undefined,
      creditValue: 0,
      creditPassword: undefined,
    };

    this.grantCredit = async function (e) {
      console.log("I AM HERE");
      console.log(
        "PIZZA",
        JSON.stringify({
          password: this.state.creditPassword,
          command: {
            type: "ADMIN_CREDIT",
            data: { bitsValue: this.state.creditValue },
          },
          bodyChannelId: this.state.creditTwitchChannelId,
          bodyUserId: this.state.creditTwitchUserId,
          windowId: "admin-console",
        })
      );
      //console.log(this.state);
      e.preventDefault();
      let response = await fetch(
        "https://us-central1-age-of-empires-307521.cloudfunctions.net/twitch-rating-charts-config",
        {
          method: "POST",
          cache: "no-cache",
          credentials: "same-origin",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
          body: JSON.stringify({
            password: this.state.creditPassword,
            command: {
              type: "ADMIN_CREDIT",
              data: { bitsValue: this.state.creditValue },
            },
            bodyChannelId: this.state.creditTwitchChannelId,
            bodyUserId: this.state.creditTwitchUserId,
            windowId: "admin-console",
          }),
        }
      );

      if (response.status == 204) {
        console.log("SUCCESS!");
        this.setState({
          modal: "config-success",
          executing: false,
        });
      } else {
        response.text().then(
          function (text) {
            console.error(text);
            this.setState({
              modal: "config-fail",
              executing: false,
              errorMessage: text,
            });
          }.bind(this)
        );
      }

      this.setState({
        executing: false,
      });

      return false;
    }.bind(this);

    this.updateConfiguration = async function (e) {
      console.log(this.state);
      e.preventDefault();
      let response = await fetch(
        "https://us-central1-age-of-empires-307521.cloudfunctions.net/twitch-rating-charts-config",
        {
          method: "POST",
          cache: "no-cache",
          credentials: "same-origin",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
          body: JSON.stringify({
            password: this.state.configPassword,
            command: {
              type: "ADMIN_CONFIG",
              data: { forceBits: this.state.configForceBits },
            },
            bodyUserId: "N/A", // Shouldn't be required but it is
            bodyChannelId: this.state.configTwitchChannelId,
            windowId: "admin-console",
          }),
        }
      );

      if (response.status == 204) {
        console.log("SUCCESS!");
        this.setState({
          modal: "config-success",
          executing: false,
        });
      } else {
        response.text().then(
          function (text) {
            console.error(text);
            this.setState({
              modal: "config-fail",
              executing: false,
              errorMessage: text,
            });
          }.bind(this)
        );
      }

      this.setState({
        executing: false,
      });

      return false;
    }.bind(this);
  }

  componentDidMount() {}

  render() {
    return (
      <Layout location={{ pathname: "/404" }}>
        <SEO title="Administration" />
        <div style={{ textAlign: "center" }}>
          <form>
            <fieldset>
              <legend>
                <h3>Grant Credit</h3>
              </legend>

              <div>
                <label htmlFor="userid">User Id</label>
                <div>
                  <input
                    id="userid"
                    name="userid"
                    type="text"
                    placeholder=""
                    onChange={function (e) {
                      this.setState({
                        creditTwitchUserId: e.target.value,
                      });
                    }.bind(this)}
                  ></input>
                </div>
              </div>

              <div>
                <label htmlFor="channelIdCredit">Channel Id</label>
                <div>
                  <input
                    id="channelIdCredit"
                    name="channelIdCredit"
                    type="text"
                    placeholder=""
                    onChange={function (e) {
                      this.setState({
                        creditTwitchChannelId: e.target.value,
                      });
                    }.bind(this)}
                  ></input>
                </div>
              </div>

              <div>
                <label htmlFor="bitsValue">Bits Value</label>
                <div>
                  <input
                    name="bitsValue"
                    type="number"
                    min="0"
                    onChange={function (e) {
                      this.setState({
                        creditValue: e.target.value,
                      });
                    }.bind(this)}
                  ></input>
                </div>
              </div>

              <div>
                <label htmlFor="passwordInputCredit">Password</label>
                <div>
                  <input
                    id="passwordInputCredit"
                    name="passwordInputCredit"
                    type="password"
                    onChange={function (e) {
                      this.setState({
                        creditPassword: e.target.value,
                      });
                    }.bind(this)}
                  ></input>
                </div>
              </div>
              <br></br>
              <button className="button" onClick={this.grantCredit}>
                <div className="button-text">Submit</div>
              </button>
            </fieldset>
          </form>
          <hr></hr>
          <form>
            <fieldset>
              <legend>
                <h3>Force Bits</h3>
              </legend>

              <div>
                <label htmlFor="channelId">Channel Id</label>
                <div>
                  <input
                    id="channelId"
                    name="channelId"
                    type="text"
                    onChange={function (e) {
                      this.setState({
                        configTwitchChannelId: e.target.value,
                      });
                    }.bind(this)}
                  ></input>
                </div>
              </div>

              <div>
                <label htmlFor="forceBits">Force Bits</label>
                <div>
                  <input
                    name="forceBits"
                    type="checkbox"
                    onChange={function (e) {
                      this.setState({
                        configForceBits: e.target.checked,
                      });
                    }.bind(this)}
                  ></input>
                </div>
              </div>

              <div>
                <label htmlFor="passwordinput">Password</label>
                <div>
                  <input
                    id="passwordinput"
                    name="passwordinput"
                    type="password"
                    onChange={function (e) {
                      this.setState({
                        configPassword: e.target.value,
                      });
                    }.bind(this)}
                  ></input>
                </div>
              </div>
              <br></br>
              <button className="button" onClick={this.updateConfiguration}>
                <div className="button-text">Submit</div>
              </button>
            </fieldset>
          </form>
        </div>
      </Layout>
    );
  }
}

export default Admin;
